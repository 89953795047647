<template>
  <base-material-dialog
    :value="value"
    icon="far fa-newspaper"
    title="News bearbeiten"
    color="primary"
    ref="test"
    :actions="[ 'save', 'close' ]"
    @save="save"
    @close="close"
    @esc="close"
    lazy
  >
    <h2>Inhalt:</h2>
    <vue-editor
      v-if="open"
      :editor-options="{
        modules: {
          imageResize: {},
          imageDropAndPaste: {
            handler: imageHandler
          }
        }
      }"
      v-model="editor"
      ref="editor"
    />
    <h2>Anhänge:</h2>
    <ul>
      <li v-for="a in News.attachments" :key="a._id">
        <a :href="`${imageBase}${a._id}`" :download="a.filename" target="_blank">{{ a.filename }}</a>
        ({{ a.mimetype }})
        <v-btn fab text x-small @click="delAttachment(a._id)"><v-icon>far fa-trash-alt</v-icon></v-btn>
      </li>
    </ul>
    <vue-dropzone
      ref="attachments"
      id="attachments"
      :options="dropzoneOptions"
    />
  </base-material-dialog>
</template>

<script>
import Vue from 'vue'
import { VueEditor, Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'
import { GRAPHQLlocal, GRAPHQLserver } from '@/env'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

const query = `
  _id text
  author { _id givenName familyName }
  dateCreated
  attachments { _id filename mimetype }
`

const resizedataURL = (data, wantedWidth, wantedHeight) => {
  const mimetype = data.match(/data:([^;]*)/)?.[1]
  return new Promise((resolve) => {
    const img = document.createElement('img')
    img.onload = function () {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = wantedWidth
      canvas.height = wantedHeight
      ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight)
      if (mimetype === 'image/jpeg' || mimetype === 'image/jpg') {
        resolve(canvas.toDataURL('image/jpeg', 60))
      } else {
        resolve(canvas.toDataURL('image/png'))
      }
    }
    img.src = data
  })
}

const resize = async (data, maxSize = 600) => {
  const { w, h } = await new Promise(resolve => {
    const i = new Image()
    i.onload = () => {
      resolve({ w: i.width, h: i.height })
    }
    i.src = data
  })

  if (w <= maxSize && h <= maxSize) return data

  let nw = maxSize
  let nh = maxSize

  if (w > h) {
    nh = h / w * maxSize
  } else {
    nw = w / h * maxSize
  }

  return resizedataURL(data, nw, nh)
}

export default {
  name: 'EditNews',

  components: {
    VueEditor,
    VueDropzone: vue2Dropzone
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: false
    }
  },

  data () {
    return {
      News: {},
      editor: '',
      open: false,
      dropzoneOptions: {
        url: () => {},
        accept: this.addAttachment,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        autoProcessQueue: false
      }
    }
  },

  computed: {
    imageBase () {
      if (!GRAPHQLlocal) return '/uploads/'

      const base = GRAPHQLserver.match(/(wss?):\/\/([^/]*)/)
      return `http${base[1] === 'wss' ? 's' : ''}://${base[2]}/uploads/`
    }
  },

  methods: {
    close () {
      this.$emit('input', false)
      this.editor = ''
      this.open = false
    },
    save () {
      const content = this.editor.replaceAll(`<img src="${this.imageBase}`, '<img src="/')
      this.mutate({
        mutation: gql`mutation($id: UUID!, $text: String!) { NewsUpdateText(id: $id, text: $text) { _id } }`,
        variables: {
          id: this.id,
          text: content
        }
      })
      this.close()
    },
    async opened () {
      this.News = (await this.query({
        query: gql`query($id: UUID!) { News(id: $id) { ${query} } }`,
        variables: {
          id: this.id
        }
      })).News
      this.editor = this.News.text.replaceAll('<img src="/', `<img src="${this.imageBase}`)
      this.open = true
      await new Promise(resolve => setTimeout(resolve, 0))
      const that = this
      const quill = this.$refs.editor.quill
      quill.getModule('toolbar').addHandler('image', function (clicked) {
        if (clicked) {
          let fileInput = this.container.querySelector('input.ql-image[type=file]')
          if (fileInput == null) {
            fileInput = document.createElement('input')
            fileInput.setAttribute('type', 'file')
            fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon')
            fileInput.classList.add('ql-image')
            fileInput.addEventListener('change', (e) => {
              const files = e.target.files
              let file
              if (files.length > 0) {
                file = files[0]
                const type = file.type
                const reader = new FileReader()
                reader.onload = (e) => {
                  // handle the inserted image
                  const dataUrl = e.target.result
                  that.imageHandler(dataUrl, type)
                  fileInput.value = ''
                }
                reader.readAsDataURL(file)
              }
            })
          }
          fileInput.click()
        }
      })
    },
    async imageHandler (imageDataUrl, type, imageData) {
      const id = Date.now()
      const tmp = `Warte auf Upload... (${id})`

      const quill = this.$refs.editor.quill
      let index = (quill.getSelection() || {}).index
      if (index === undefined || index < 0) index = quill.getLength()
      quill.insertEmbed(index, 'text', tmp, 'user')
      quill.setSelection(index + tmp.length)

      const resized = await resize(imageDataUrl)

      this.mutate({
        mutation: gql`mutation($filedata: String!) { FileUpload(filedata: $filedata) }`,
        variables: {
          filedata: resized
        }
      }).then((data) => {
        const contents = quill.getContents()
        const selection = quill.getSelection()
        const ops = []

        contents.ops.forEach(op => {
          if (typeof op.insert === 'string' && !!op.insert.includes(tmp)) {
            const opsplit = op.insert.split(tmp)
            if (opsplit[0] !== '') ops.push({ insert: opsplit[0] })
            ops.push({ insert: { image: `${this.imageBase}${data.FileUpload}` } })
            if (opsplit[1] !== '') ops.push({ insert: opsplit[1] })
          } else {
            ops.push(op)
          }
        })

        quill.setContents(ops)
        quill.setSelection(selection.index)
      })
    },
    async addAttachment (files) {
      const toBase64 = file => new Promise((resolve) => {
        var reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.readAsDataURL(file)
      })

      const filename = files.name
      const mimetype = files.type
      const filedata = await toBase64(files)

      this.$refs.attachments.removeFile(files)

      const neu = await this.mutate({
        mutation: gql`mutation($id: UUID!, $filedata: String!, $filename: String!, $mimetype: String!) {
          NewsUpdateAttachmentAdd(id: $id, filedata: $filedata, filename: $filename, mimetype: $mimetype) {
            ${query}
          }
        }`,
        variables: {
          id: this.id,
          filedata,
          filename,
          mimetype
        }
      })

      Vue.set(this.News, 'attachments', neu.NewsUpdateAttachmentAdd.attachments)
    },
    delAttachment (id) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $file: UUID!) {
          NewsUpdateAttachmentDelete(id: $id, file: $file) {
            ${query}
          }
        }`,
        variables: {
          id: this.id,
          file: id
        }
      }).then(neu => {
        Vue.set(this.News, 'attachments', neu.NewsUpdateAttachmentDelete.attachments)
      })
    }
  },

  watch: {
    value () {
      if (this.value) {
        this.opened()
      }
    }
  }
}
</script>

<style scoped>

</style>
