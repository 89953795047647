var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-material-dialog',{ref:"test",attrs:{"value":_vm.value,"icon":"far fa-newspaper","title":"News bearbeiten","color":"primary","actions":[ 'save', 'close' ],"lazy":""},on:{"save":_vm.save,"close":_vm.close,"esc":_vm.close}},[_c('h2',[_vm._v("Inhalt:")]),(_vm.open)?_c('vue-editor',{ref:"editor",attrs:{"editor-options":{
      modules: {
        imageResize: {},
        imageDropAndPaste: {
          handler: _vm.imageHandler
        }
      }
    }},model:{value:(_vm.editor),callback:function ($$v) {_vm.editor=$$v},expression:"editor"}}):_vm._e(),_c('h2',[_vm._v("Anhänge:")]),_c('ul',_vm._l((_vm.News.attachments),function(a){return _c('li',{key:a._id},[_c('a',{attrs:{"href":`${_vm.imageBase}${a._id}`,"download":a.filename,"target":"_blank"}},[_vm._v(_vm._s(a.filename))]),_vm._v(" ("+_vm._s(a.mimetype)+") "),_c('v-btn',{attrs:{"fab":"","text":"","x-small":""},on:{"click":function($event){return _vm.delAttachment(a._id)}}},[_c('v-icon',[_vm._v("far fa-trash-alt")])],1)],1)}),0),_c('vue-dropzone',{ref:"attachments",attrs:{"id":"attachments","options":_vm.dropzoneOptions}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }